import React, { Component, Fragment } from "react";
// import Accordion from "react-bootstrap/Accordion";
// import Card from "react-bootstrap/Card";
import { categoryService } from "../../../core/commons/services/entity-services/category.service";

import clearDeep from "clean-deep";
import utilService, {
  formatCatString,
  cleanAmazonUrl,
  buildBestSellerUrl,
  promiseSetState,
} from "../../../core/commons/services/helpers/util.service";
import toastService from "../../../core/commons/services/helpers/toast.service";
import { userService } from "../../../core/commons/services/entity-services/user.service";
import CreatableSelect from "react-select/creatable";

export default class ViewEditCategory extends Component {
  constructor() {
    super();

    this.state = {
      categoryInfo: {
        amazonUrl: "",
        catName: "",
        catString: "",
        created_on: "",
        id: "",
        isActive: "",
        one: "",
        one_avg_rating: "",
        one_num_reviews: "",
        ten: "",
        ten_avg_rating: "",
        ten_num_reviews: "",
        updated_on: "",
      },
      isDataLoaded: false,
      isEditing: false,
      isDeleting: false,
      isUpdating: false,
      bestSellerUrl: "",
      categoryNames: [],
      selectedCatName: {},
    };
  }

  getCategoryNames = async () => {
    try {
      const request = await categoryService.getCatNames();
      if (request.success) {
        const categoryNames = clearDeep(request.data).map((x) => ({
          value: x,
          label: x,
        }));
        console.log(`categoryInfo -->`, categoryNames);
        this.setState({ categoryNames });
      } else {
        // alert("")
        toastService.failedToLoad("category names");
        // utilService.showToast("Failed to get category data")
      }
      // console.log(request);
    } catch (error) {
      // utilService.showToast("Oops something went wrong")
      toastService.somethingWentWrong();
      console.log(error);
    }
  };

  getCategory = async (id) => {
    try {
      const request = await categoryService.get(id);
      if (request.success) {
        console.log(
          `mazonId -->`,
          this.getAmazonUrlId(request.data["amazonUrl"])
        );
        const catData = request.data;
        catData["amazonUrl"] = this.getAmazonUrlId(catData["amazonUrl"]);
        const categoryInfo = clearDeep(catData, { emptyStrings: false });
        console.log(`categoryInfo -->`, categoryInfo);

        const categoryName = {
          value: categoryInfo["catName"],
          label: categoryInfo["catName"],
        };

        this.setState({
          categoryInfo,
          selectedCatName: categoryName,
        });
      } else {
        // alert("")
        toastService.failedToLoad("categoty");
        // utilService.showToast("Failed to get category data")
      }
      // console.log(request);
    } catch (error) {
      // utilService.showToast("Oops something went wrong")
      toastService.somethingWentWrong();
      console.log(error);
    }
  };

  handleGetCategory = async () => {
    const { catId } = this.props;

    if (catId != null) {
      await this.getCategory(catId);
    }
  };

  async componentDidMount() {
    const bestSellerUrl = buildBestSellerUrl(
      userService.getMaket(),
      userService.getBookType()
    );
    this.setState({ bestSellerUrl: bestSellerUrl });
    await this.getCategoryNames();
    await this.handleGetCategory();
  }

  async componentDidUpdate(prevProps) {
    const isSameCatId = this.props.catId == prevProps.catId;

    if (!isSameCatId) {
      await this.handleGetCategory();
    }
  }

  handleFormState = (e) => {
    const value = e.target.value || "";
    const name = e.target.attributes["name"].value;
    // console.log();
    const categoryInfo = this.state.categoryInfo;
    categoryInfo[name] = value;
    this.setState({
      categoryInfo,
    });
  };

  handleFormSubmit = async (action) => {
    console.log(`action -->`, action);
    // console.log(this.state.categoryInfo);
    if (action == "update") {
      const confirmDelete = window.confirm(
        "Are you sure you want to update this record ? "
      );
      if (confirmDelete) {
        await this.updateRecord();
      }
    } else if (action == "delete") {
      const confirmDelete = window.confirm(
        "Are you sure you want to delete this record? "
      );
      if (confirmDelete) {
        await this.deleteRecord();
      }
    }
  };

  handleSelectChange = async (newValue, actionMeta) => {
    let selectedValue = "";

    if (newValue != null) {
      if (actionMeta.action == "create-option") {
        const { categoryNames } = this.state;
        const newCatName = [...categoryNames, newValue];
        await promiseSetState.bind(this)(this.setState.bind(this), {
          categoryNames: newCatName,
        });
      }
      selectedValue = newValue.value;
    }

    this.updateFormState("catName", selectedValue);
  };

  getAmazonUrlId = (url) => {
    const regex = /(?:[/dp/]|$)([A-Z0-9]{3,})/gm;
    // const url = ``;
    let matches;

    let amazonUrlId = "";

    while ((matches = regex.exec(url)) !== null) {
      // This is necessary to avoid infinite loops with zero-width matches
      if (matches.index === regex.lastIndex) {
        regex.lastIndex++;
      }

      console.log(`Matches -->`, matches);

      // The result can be accessed through the `m`-variable.
      matches.forEach((match, groupIndex) => {
        // console.log(`Found match, group ${groupIndex}: ${match}`);
        if (!match.includes("/")) {
          amazonUrlId = match;
        }
      });
    }
    return amazonUrlId;
  };

  updateFormState = (key, value) => {
    const categoryInfo = this.state.categoryInfo;
    categoryInfo[key] = value;
    this.setState({
      categoryInfo,
    });
  };

  updateRecord = async () => {
    try {
      const { categoryInfo, bestSellerUrl } = this.state;
      const { catId, updateList } = this.props;
      console.log(`this.prop -->`, this.props);
      const { id, updated_on, ...postData } = clearDeep(categoryInfo);

      postData["catString"] = formatCatString(postData["catString"]);
      const amazonUrlId = document.querySelector("#amazonUrlId").innerText;
      postData["amazonUrl"] = `https://${bestSellerUrl}${amazonUrlId}`;

      postData["amazonUrl"] = cleanAmazonUrl(postData["amazonUrl"]);
      console.log(`postData -->`, postData);

      this.setState({ isUpdating: true });

      const updateRequest = await categoryService.update(catId, postData);

      console.log(updateRequest);

      if (updateRequest.success) {
        updateList(catId, updateRequest.data);
        toastService.showToast("Item updated", true);
      } else {
        toastService.showToast("Failed to update item");
      }

      setTimeout(() => this.setState({ isUpdating: false }), 500);
    } catch (error) {
      console.log(error);
      console.log(error.message);
      toastService.somethingWentWrong();
      setTimeout(() => this.setState({ isUpdating: false }, 500));
    }
  };

  deleteRecord = async () => {
    try {
      const { catId, updateList } = this.props;

      this.setState({ isDeleting: true });

      const deleteRequest = await categoryService.remove(catId);

      console.log(deleteRequest);

      if (deleteRequest.success) {
        updateList(catId, deleteRequest.data, true);
        toastService.showToast("Item deleted", true);
      } else {
        toastService.showToast("Failed to delete item");
      }

      setTimeout(() => this.setState({ isDeleting: false }), 500);
    } catch (error) {
      console.log(error);
      console.log(error.message);
      toastService.somethingWentWrong();
      setTimeout(() => this.setState({ isDeleting: false }, 500));
    }
  };

  navigateToUrl(href, newTab = true) {
    var a = document.createElement("a");
    a.href = href;
    if (newTab) {
      a.setAttribute("target", "_blank");
    }
    a.click();
  }

  openAmazonUrl = () => {
    const { bestSellerUrl } = this.state;
    const amazonUrlId = document.querySelector("#amazonUrlId").innerText;
    const amazonUrl = `https://${bestSellerUrl}${amazonUrlId}`;

    this.navigateToUrl(amazonUrl);
  };

  render() {
    const {
      amazonUrl,
      catName,
      catString,
      id,
      isActive,
      one,
      one_avg_rating,
      one_num_reviews,
      ten,
      ten_avg_rating,
      ten_num_reviews,
    } = this.state.categoryInfo;
    const {
      isDeleting,
      isUpdating,
      bestSellerUrl,
      categoryNames,
      selectedCatName,
    } = this.state;

    console.log(`selectedCatName -->`, selectedCatName);
    return (
      <Fragment>
        <div className="form">
          <div className="form-group">
            <label htmlFor="">Category Name</label>
            {/* <input
              onChange={this.handleFormState}
              onChange={this.handleFormState}
              name="catName"
              value={catName}
              type="text"
              className="form-control"
            /> */}
            {Object.keys(selectedCatName).length > 0 && (
              <CreatableSelect
                isClearable
                onChange={this.handleSelectChange}
                options={categoryNames}
                defaultValue={selectedCatName}
              />
            )}
          </div>
          <div className="form-group">
            <label htmlFor="">Category String</label>
            <input
              onChange={this.handleFormState}
              type="text"
              value={catString}
              name="catString"
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="">
              Amazon URL (
              <a
                href="#"
                style={{ fontSize: "0.8em" }}
                onClick={() => this.openAmazonUrl()}
              >
                Open Url
              </a>
              )
            </label>
            <div className="row">
              <div className="col-md-10">
                <div className="amazon-url-container">
                  {/* <input value={amazonUrl} name="amazonUrl" onChange={this.handleFormState} type="text" className="form-control" /> */}
                  <label htmlFor="">
                    {bestSellerUrl}
                    <span
                      contentEditable={true}
                      id="amazonUrlId"
                      data-placeholder="xxxxxxx"
                    >
                      {amazonUrl}
                    </span>
                  </label>
                </div>
              </div>

              <div className="col-md-2">
                {/* <button className="btn btn-primary">Open</button> */}
              </div>
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="">Rank #1</label>
            <div className="form-row">
              <div className="form-group col-md-4">
                <label htmlFor="">ABSR</label>
                <input
                  value={one}
                  name="one"
                  onChange={this.handleFormState}
                  type="text"
                  className="form-control"
                />
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="">Reviews</label>
                <input
                  value={one_num_reviews}
                  name="one_num_reviews"
                  onChange={this.handleFormState}
                  type="text"
                  className="form-control"
                />
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="">Rating</label>
                <input
                  value={one_avg_rating}
                  name="one_avg_rating"
                  onChange={this.handleFormState}
                  type="text"
                  className="form-control"
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="">Rank #10</label>
            <div className="form-row">
              <div className="form-group col-md-4">
                <label htmlFor="">ABSR</label>
                <input
                  value={ten}
                  name="ten"
                  onChange={this.handleFormState}
                  type="text"
                  className="form-control"
                />
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="">Reviews</label>
                <input
                  value={ten_num_reviews}
                  name="ten_num_reviews"
                  onChange={this.handleFormState}
                  type="text"
                  className="form-control"
                />
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="">Rating</label>
                <input
                  value={ten_avg_rating}
                  name="ten_avg_rating"
                  onChange={this.handleFormState}
                  type="text"
                  className="form-control"
                />
              </div>
            </div>
          </div>

          <div className="form-group w-100 d-flex justify-content-between">
            <button
              disabled={isUpdating || isDeleting}
              className="btn btn-danger"
              onClick={() => this.handleFormSubmit("delete")}
            >
              {isDeleting ? (
                <span
                  className="spinner-border spinner-border-sm mr-1"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                ""
              )}
              Delete
            </button>
            <button
              disabled={isDeleting || isUpdating}
              className="btn btn-primary"
              onClick={() => this.handleFormSubmit("update")}
            >
              {isUpdating ? (
                <span
                  className="spinner-border spinner-border-sm mr-1"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                ""
              )}
              Save Changes
            </button>
          </div>
        </div>
      </Fragment>
    );
  }
}
