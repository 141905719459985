import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { withResizeDetector } from 'react-resize-detector'
import { useEffect } from 'react'
import { useState } from 'react'
import layoutService from '../../../commons/services/helpers/layout.service'
import { userService } from '../../../commons/services/entity-services/user.service'
import ModalComponent from '../../../commons/components/modal.component'
import BookTypeSelector from '../../../commons/components/book-type-selector'
import { categoryService } from '../../../commons/services/entity-services/category.service'
import { useDispatch } from 'react-redux'
import { changeMarketAction, endDataLoadingAction, fetchAllCategoriesAction, startDataLoadingAction } from '../../../../store/actions/category.actions'

const HeaderComponent = ({ width, height }) => {

    const [openMenu, setOpenMenu] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [selectedCountry, setSelectedCountry] = useState("United States")
    const [previousSelectedCountry, setPreviousSelectedCountry] = useState(userService.getMaket())
    const dispatch = useDispatch()
    const logoutUser = () => {

        if (window.confirm('Are you sure you want to logout ?')) {
            localStorage.clear()
            setTimeout(() => {
                window.location.href = "/login"
            }, 500)
        }
    }

    const toggleMenu = () => setOpenMenu(!openMenu)
    const getCategoryItems = async(country)=>{
        dispatch(startDataLoadingAction())
        dispatch(changeMarketAction(country))
        const items = await categoryService.getAll()
        dispatch(endDataLoadingAction())
        if(items.success){
            dispatch(fetchAllCategoriesAction(items.data))
        }
      
        //window.location.reload()
    }
    useEffect(() => {
        layoutService.handleMobileHeader(width)
        setOpenMenu(false)
    }, [width])

    useEffect(() => {
        layoutService.handleMobileSidebarToggle(openMenu)
    }, [openMenu])

    useEffect(() => {
        console.log("updating country")
        let market = null
        if(selectedCountry === "United Kingdom"){
            market = 'uk'
        }else if (selectedCountry ==="United States"){
            market ="us"
        }
        else if(selectedCountry==="Germany"){
            market ="de"
        }
        userService.setMarket(market)
        getCategoryItems(selectedCountry)
       
        // if(openModal == fase){}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCountry])

   
    const switchMarket = market => {
        setPreviousSelectedCountry(selectedCountry)
        console.log(selectedCountry,market)
        setSelectedCountry(market)
        setOpenModal(true)
    }

    const handleTypeSwitch = type => {
        console.log(type);
        userService.setBookType(type)
        window.location.reload()
    }
    return (

        <Fragment>
            <header className="u-header">
                <div className="u-header-left">
                    <Link to="/" className="u-header-logo">
                        {/* <img className="u-logo-desktop pad-logo" src="/assets/images/logo-simple.png" width="130" alt="" /> */}
                        <img className="img-fluid" src="/assets/images/publisher_rocket.png" />
                    </Link>

                </div>

                <div className="u-header-middle">
                    <a onClick={toggleMenu} id="mobile-menu-invoker" className="js-sidebar-invoker u-sidebar-invoker" >
                        <i className="fa fa-bars u-sidebar-invoker__icon--open"></i>
                        <i className="fa fa-times u-sidebar-invoker__icon--close"></i>
                    </a>
                </div>

                <div className="u-header-right">
                    {/* <div style={{ display: "flex" }}>
                        <Link to="/retiredlist" className="recordsbtn">
                            <button className="btn btn-primary" >
                                Retired Records
                            </button>
                        </Link>
                        <Link to="/skipedlist" className="recordsbtn">
                            <button className="btn btn-primary" >
                               Skip Records
                            </button>
                        </Link>
                    </div> */}
                    <div className="dropdown">
                        <div className="country-logo">
                            <div className="country">
                                <img src="/assets/images/country-logo/us.png" onClick={() => switchMarket("United States")} className={(selectedCountry != "United States" ? "unselected" : "")} />
                                <label htmlFor="">US</label>
                            </div>

                            <div className="country">
                                <img src="/assets/images/country-logo/uk.png" onClick={() => switchMarket("United Kingdom")} className={(selectedCountry != "United Kingdom" ? "unselected" : "")} />
                                <label htmlFor="">UK</label>
                            </div>



                            <div className="country">

                                <img src="/assets/images/country-logo/de.png" onClick={() => switchMarket("Germany")} className={(selectedCountry != "Germany" ? "unselected" : "")} />
                                <label htmlFor="">DE</label>
                            </div>




                        </div>

                    </div>

                    <div className="dropdown ml-3">
                        <span style={{ "cursor": "pointer" }} className="u-sidebar-nav-menu__link" onClick={logoutUser} >
                            {/* <i className="fa fa-sign-out u-sidebar-nav-menu__item-icon"></i> */}
                            <span className="u-sidebar-nav-menu__item-title">Logout</span>
                        </span>

                    </div>

                </div>

            </header>
          {/*    <BookTypeSelector keyboard={false} backdrop={"static"} isShow={openModal} handleClose={() => { setOpenModal(false); setSelectedCountry(previousSelectedCountry) }} />
           <ModalComponent show={openModal}
                    size="sm"
                    heading={"Select Book Type"}
                    backdrop="static"
                    keyboard={false}
                    handleClose={{ action: () => {setOpenModal(false);setSelectedCountry(previousSelectedCountry)}, name: "Close" }}>

                    <div className="form-group text-center">
                        <select className="form-control" onChange={(e) => e.target.value ? handleTypeSwitch(e.target.value) : null } name="" id="">
                            <option>Select Book Type</option>
                            <option value="book">Book</option>
                            <option value="ebook">EBook</option>
                        </select>
                    </div>

                </ModalComponent> */}

        </Fragment>
    )
}

const Header = withResizeDetector(HeaderComponent)
export default Header
