import React, { Component, Fragment } from "react";
// import Accordion from "react-bootstrap/Accordion";
// import Card from "react-bootstrap/Card";
import { categoryService } from "../../../core/commons/services/entity-services/category.service";

import clearDeep from "clean-deep";
import utilService, {
  promiseSetState,
  formatCatString,
  cleanAmazonUrl,
  buildBestSellerUrl,
} from "../../../core/commons/services/helpers/util.service";
import toastService from "../../../core/commons/services/helpers/toast.service";

import CreatableSelect from "react-select/creatable";
// import { value } from "numeral";
import { userService } from "../../../core/commons/services/entity-services/user.service";

export default class AddCateforyRecord extends Component {
  constructor() {
    super();

    this.state = {
      categoryNames: [],
      categoryInfo: {
        amazonUrl: "",
        catName: "",
        catString: "",
        created_on: "",
        isActive: 1,
        one: "",
        one_avg_rating: "",
        one_num_reviews: "",
        ten: "",
        ten_avg_rating: "",
        ten_num_reviews: "",
      },
      isDataLoaded: false,
      isEditing: false,
      isDeleting: false,
      isCreating: false,
      bestSellerUrl: "",
    };
  }

  getCategoryNames = async () => {
    try {
      const request = await categoryService.getCatNames();
      if (request.success) {
        const categoryNames = clearDeep(request.data).map((x) => ({
          value: x,
          label: x,
        }));
        console.log(`categoryInfo -->`, categoryNames);
        this.setState({ categoryNames });
      } else {
        // alert("")
        toastService.failedToLoad("category names");
        // utilService.showToast("Failed to get category data")
      }
      // console.log(request);
    } catch (error) {
      // utilService.showToast("Oops something went wrong")
      toastService.somethingWentWrong();
      console.log(error);
    }
  };

  async componentDidMount() {
    const bestSellerUrl = buildBestSellerUrl(
      userService.getMaket(),
      userService.getBookType()
    );
    this.setState({ bestSellerUrl: bestSellerUrl });
    await this.getCategoryNames();
  }

  async componentDidUpdate(prevProps) {
    // const isSameCatId = this.props.catId == prevProps.catId
    // if (!isSameCatId) {
    //     await this.handleGetCategoryNames()
    // }
  }

  handleFormState = (e) => {
    const value = e.target.value || "";
    const name = e.target.attributes["name"].value;
    // console.log();
    this.updateFormState(name, value);
  };

  handleFormSubmit = async (action) => {
    console.log(`action -->`, action);
    // console.log(this.state.categoryInfo);
    if (action == "add") {
      console.log(this.state);

      await this.addRecord();
    }
  };

  addRecord = async () => {
    try {
      const { categoryInfo, bestSellerUrl } = this.state;
      const { updateList } = this.props;
      console.log(`this.prop -->`, this.props);
      const { id, updated_on, ...postData } = clearDeep(categoryInfo);
      postData["catString"] = formatCatString(postData["catString"]);
      const amazonUrlId = document.querySelector("#amazonUrlId").innerText;
      postData["amazonUrl"] = `https://${bestSellerUrl}${amazonUrlId}`;
      postData["amazonUrl"] = cleanAmazonUrl(postData["amazonUrl"]);
      console.log(`postData -->`, postData);

      this.setState({ isCreating: true });

      const createRequest = await categoryService.create(postData);

      console.log(createRequest);

      if (createRequest.success) {
        updateList(null, createRequest.data, false, true);
        toastService.showToast("Item Created", true);
      } else {
        toastService.showToast("Failed to create item");
      }

      setTimeout(() => this.setState({ isCreating: false }), 500);
    } catch (error) {
      console.log(error);
      console.log(error.message);
      toastService.somethingWentWrong();
      setTimeout(() => this.setState({ isCreating: false }, 500));
    }
  };

  updateFormState = (key, value) => {
    const categoryInfo = this.state.categoryInfo;
    categoryInfo[key] = value;
    this.setState({
      categoryInfo,
    });
  };

  handleSelectChange = async (newValue, actionMeta) => {
    let selectedValue = "";

    if (newValue != null) {
      if (actionMeta.action == "create-option") {
        const { categoryNames } = this.state;
        const newCatName = [...categoryNames, newValue];
        await promiseSetState.bind(this)(this.setState.bind(this), {
          categoryNames: newCatName,
        });
      }
      selectedValue = newValue.value;
    }

    this.updateFormState("catName", selectedValue);
  };

  render() {
    const {
      amazonUrl,
      catName,
      catString,
      id,
      isActive,
      one,
      one_avg_rating,
      one_num_reviews,
      ten,
      ten_avg_rating,
      ten_num_reviews,
    } = this.state.categoryInfo;
    const { isDeleting, isCreating, categoryNames, bestSellerUrl } = this.state;
    return (
      <Fragment>
        <div className="form">
          <div className="form-group">
            <label htmlFor="">Category Name</label>
            {/* <input onChange={this.handleFormState} onChange={this.handleFormState} name="catName" value={catName} type="text" className="form-control" /> */}
            <CreatableSelect
              isClearable
              onChange={this.handleSelectChange}
              options={categoryNames}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">Category String</label>
            <input
              onChange={this.handleFormState}
              type="text"
              value={catString}
              name="catString"
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="">Amazon URL</label>
            <div className="amazon-url-container">
              {/* <input value={amazonUrl} name="amazonUrl" onChange={this.handleFormState} type="text" className="form-control" /> */}
              <label htmlFor="">
                {bestSellerUrl}
                <span
                  contentEditable={true}
                  id="amazonUrlId"
                  data-placeholder="xxxxxxx"
                ></span>
              </label>
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="">Rank #1</label>
            <div className="form-row">
              <div className="form-group col-md-4">
                <label htmlFor="">ABSR</label>
                <input
                  value={one}
                  name="one"
                  onChange={this.handleFormState}
                  type="text"
                  className="form-control"
                />
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="">Reviews</label>
                <input
                  value={one_num_reviews}
                  name="one_num_reviews"
                  onChange={this.handleFormState}
                  type="text"
                  className="form-control"
                />
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="">Rating</label>
                <input
                  value={one_avg_rating}
                  name="one_avg_rating"
                  onChange={this.handleFormState}
                  type="text"
                  className="form-control"
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="">Rank #10</label>
            <div className="form-row">
              <div className="form-group col-md-4">
                <label htmlFor="">ABSR</label>
                <input
                  value={ten}
                  name="ten"
                  onChange={this.handleFormState}
                  type="text"
                  className="form-control"
                />
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="">Reviews</label>
                <input
                  value={ten_num_reviews}
                  name="ten_num_reviews"
                  onChange={this.handleFormState}
                  type="text"
                  className="form-control"
                />
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="">Rating</label>
                <input
                  value={ten_avg_rating}
                  name="ten_avg_rating"
                  onChange={this.handleFormState}
                  type="text"
                  className="form-control"
                />
              </div>
            </div>
          </div>

          <div className="form-group w-100 d-flex justify-content-end align-items=end">
            {/* <button disabled={isUpdating || isDeleting} className="btn btn-danger" onClick={() => this.handleFormSubmit("delete")}>
                            {isDeleting ? (<span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>) : ""}
                            Delete</button> */}
            <button
              disabled={isCreating}
              className="btn btn-primary"
              onClick={() => this.handleFormSubmit("add")}
            >
              {isCreating ? (
                <span
                  className="spinner-border spinner-border-sm mr-1"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                ""
              )}
              Add Record
            </button>
          </div>
        </div>
      </Fragment>
    );
  }
}
