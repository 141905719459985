import CategoryListpage from "./pages/CategoryList"
import RetiredListpage from "./pages/RetiredList"
import SkipedListpage from "./pages/SkipedList"

const BASE_PATH = "/dashboard"
const RETIRED_PATH = "/retiredlist"
const SKIPED_PATH = "/skipedlist"

const CATEGORY_MANAGEMENT_ROUTES = [

    {
        path: BASE_PATH,
        exact: true,
        component: CategoryListpage,
        isSideBar: true,
        label: "Category"
    },
    {
        path: RETIRED_PATH,
        exact: true,
        component: RetiredListpage,
        isSideBar: true,
        label: "Retired"
    },
    {
        path: SKIPED_PATH,
        exact: true,
        component: SkipedListpage,
        isSideBar: true,
        label: "Skipped"
    }

]


export default CATEGORY_MANAGEMENT_ROUTES