import axios from "axios";
import * as utilService from "./util.service"

import configs from "../../../config"
import { userService } from "../entity-services/user.service";

export default class HttpClient {

    resourceUrl
    axiosClient = null
    headers = null
    constructor(resourceBaseName, injectToken = null) {

        this.headers = {
            "Content-type": "application/json",
        }

        if (injectToken) {
            this.headers['Authorization'] = `Bearer ${injectToken}`
        }

        this.axiosClient = axios.create({
            baseURL: configs.API_BASE_URL,
            headers: this.headers
        });

        // this.resourceUrl = `/${resourceBaseName}`
        this.setResourceUrl(resourceBaseName)
        console.log("this.setResourceUrl(resourceBaseName) ++++>",this.setResourceUrl(resourceBaseName))

    }

        
    setResourceUrl =  resourceBaseName => this.resourceUrl = `/${resourceBaseName}`
    
    get axios() {
        return this.axiosClient
    }

    async getAll(paginate = null) {
        try {
            let response;
            if (paginate != null) {
                let { page, perPage } = paginate
                page = page ? page : 1
                perPage = perPage ? perPage : 10
                console.log("resourceUrl===>",this.resourceUrl)
                response = await this.axiosClient.get(`${this.resourceUrl}?page=${page}&perPage=${perPage}`)
                console.log("getAll res ====>",response)
            } else {
               
                console.log( userService.getMaket())
                response = await this.axiosClient.get(`${userService.getMaket()}/category`);
                console.log("getAll res ====>",response)

            }
            return response.data
        } catch (error) {
            return utilService.failedHttpResponse()
        }
    }

    async updateCategory(id,data){
        
        try{    
      

            const response = await this.axiosClient.put(`${userService.getMaket()}/category/${id}`,data)
            return {status:response.status,data:response.data.data}

        }catch(error){
            return utilService.failedHttpResponse()
        }
    }

    async getAllRetired(paginate = null) {
        try {
            let response;
            if (paginate != null) {
                let { page, perPage } = paginate
                page = page ? page : 1
                perPage = perPage ? perPage : 10
                console.log("resourceUrl===>",this.resourceUrl)
                response = await this.axiosClient.get(`${this.resourceUrl}/retired?page=${page}&perPage=${perPage}`)
                alert("item",response)
                console.log("getAllRetired res ====>",response)
            } else {
                
                response = await this.axiosClient.get(`${this.resourceUrl}/retired`);
                console.log("getAllRetired res ====>",response)

            }
            return response.data
        } catch (error) {
            return utilService.failedHttpResponse()
        }
    }

    async getAllSkipped(paginate = null) {
        try {
            let response;
            if (paginate != null) {
                let { page, perPage } = paginate
                page = page ? page : 1
                perPage = perPage ? perPage : 10
                console.log("resourceUrl===>",this.resourceUrl)
                response = await this.axiosClient.get(`${this.resourceUrl}/skipped?page=${page}&perPage=${perPage}`)
                console.log("getAllSkipped res ====>",response)

            } else {
                response = await this.axiosClient.get(`${this.resourceUrl}/skipped`);
                console.log("getAllSkipped res ====>",response)

            }
            return response.data
        } catch (error) {
            return utilService.failedHttpResponse()
        }
    }
    async getRequest(urlPath) {
        try {
            const { data } = await this.axiosClient.get(urlPath);
            return data
        } catch (error) {
            return utilService.failedHttpResponse()
        }
    }
    async deleteRequest(urlPath) {
        try {
            const { data } = await this.axiosClient.delete(urlPath);
            return data
        } catch (error) {
            return utilService.failedHttpResponse()
        }
    }

    async postRequest(urlPath, formData) {
        try {
            const { data ,status} = await this.axiosClient.post(urlPath, formData);
            console.log(status)
            console.log(`Data ->`, data);
            return {success:true,data}
        } catch (error) {
            console.log(error.message);
            return utilService.failedHttpResponse("Oops something went wrong")
        }
    }

    async get(id) {

        try {
            const { data } = await this.axiosClient.get(`${this.resourceUrl}/${id}`);
            return data
        } catch (error) {
            return utilService.failedHttpResponse()
        }
    };

    async create(postData) {

        try {
            const { data } = await this.axiosClient.post(`${this.resourceUrl}`, postData);
            return data
        } catch (error) {
            console.log(error);
            return utilService.failedHttpResponse()
        }
    };

    async update(id, updateData) {

        try {
            const { data } = await this.axiosClient.put(`${this.resourceUrl}/${id}`, updateData);
            return data
        } catch (error) {
            console.log(error);
            return utilService.failedHttpResponse()
        }
    };

    async remove(id) {


        try {
            const { data } = await this.axiosClient.delete(`${this.resourceUrl}/${id}`);
            return data
        } catch (error) {
            return utilService.failedHttpResponse()
        }
    };

    async removeAll() {

        try {
            const { data } = await this.axiosClient.delete(`${this.resourceUrl}`);
            return data
        } catch (error) {
            return utilService.failedHttpResponse()
        }
    };

}