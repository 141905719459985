import React, { Component, Fragment } from "react"
import DataTable from "react-data-table-component"
import { categoryService } from "../../../core/commons/services/entity-services/category.service"
import ViewEditCategory from "../components/view-edit-category"

import ModalComponent from "../../../core/commons/components/modal.component"
import CategorySearch from "../components/category-search"
import { userService } from "../../../core/commons/services/entity-services/user.service"
import BookTypeSelector from "../../../core/commons/components/book-type-selector"
import moment from "moment"
import AddCateforyRecord from "../components/add-category-record"
import {
  fetchAllCategoriesAction,
  setSearchResultAction,
  restoreSearchAction,
} from "../../../store/actions/category.actions"
import { connect } from "react-redux"
import MediaSelector from "../../../core/commons/components/media-selector"
import EditCategoryModal from "../components/edit-category"


class CategoryListpage extends Component {
  constructor() {
    super()
    const dataTableColumns = this.setupDataColumns()
    this.state = {
      dataTableColumns: dataTableColumns,
      categoryItems: [],
      dataTableLoading: false,
      isLoadedData: false,
      selectedCatId: null,
      isShowModal: false,
      pageTitle: "",
      progressPending: true,
      isShowAddModal: false,
      selectedCat:null
    }
  }

  async getCategoryItems() {
    const items = await categoryService.getAll()
    if (!items.success) {
      console.log("Failed to get category data")
      this.setState({ categoryItems:[],progressPending: false })
    } else {
      this.props.fetchAllCategoriesAction(items.data)
      this.setState({ categoryItems: items.data, progressPending: false })
      console.log(this.state)
      console.log(this.props)
    }
  }

  async componentWillMount() {
    console.log(this.props.selectedCategories)
    try {
      await this.handlePageTitle()
      await this.getCategoryItems()
    } catch (error) {
      console.log(error)
    }
  }

  setupDataColumns = () => {
    const dataTableColumns = [
        {
            name: "Category ID",
            selector: "CategoryID",
            wrap: true,
          },
      {
        name: "Category String",
        selector: "CategoryString",
        grow: 4,
        wrap: true,
      },
      {
        name: "Amazon Url",
        selector: "amazonUrl",
        cell: (row) => {
          let url = row.CategoryURL
          if (!url.includes("https://")) {
            url = `https://${url}`
          }
          return (
            <a href={url} target="_blank">
              Open
            </a>
          )
        },
      },
      {
        name: "#1 ABSR",
        selector: "ABSR1",
        grow: 1,
        wrap: true,
        sortable: true,
      },
      {
        name: "#10 ABSR",
        selector: "ABSR10",
        grow: 1,
        wrap: true,
        sortable: true,
      },

      {
        name: "Percent Large Publisher",
        selector: "Percent_Large_Publisher",
        grow: 1,
        cell: (row) =>
          row.Percent_Large_Publisher == 5
            ? `< ${row.Percent_Large_Publisher} %`
            : row.Percent_Large_Publisher == 95
            ? `> ${row.Percent_Large_Publisher} %`
            : row.Percent_Large_Publisher == null
            ? "N/A"
            : `${row.Percent_Large_Publisher} %`,
        sortable: true,
      },

      {
        name: "Percent KU",
        selector: "Percent_KU",
        grow: 1,
        // wrap: true,
        cell: (row) =>
          row.Percent_KU == 5
            ? `< ${row.Percent_KU} %`
            : row.Percent_KU == 95
            ? `> ${row.Percent_KU} %`
            : row.Percent_KU == null
            ? "N/A"
            : `${row.Percent_KU} %`,
        sortable: true,
      },
      {
        name: "Status",
        selector: "RetiredFlag",
        grow: 1,
        wrap: true,
        cell: ({ RetiredFlag }) => (
          <span className={`badge badge-${RetiredFlag ? "danger" : "success"}`}>
            {RetiredFlag ? "Retired" : "Live"}
          </span>
        ),
      },
      {
        name: "Last Update",
        selector: "ChangeDate",
        grow: 1,
        format: (row) => moment(row["ChangeDate"]).format("MM/DD/YYYY"),
        wrap: true,
        sortable: true,
      },
      {
        name: "Created At",
        selector: "CreationDate",
        format: (row) => moment(row["CreationDate"]).format("MM/DD/YYYY"),
        grow: 1,
        wrap: true,
        sortable: true,
      },
    ]

    dataTableColumns.push({
      cell: (row) => (
        <button
          className="btn btn-outline-primary p-1"
          style={{ width: "80px" }}
          onClick={() => {
            console.log(row)
            this.setState({ selectedCat:row, selectedCatId: row.CategoryID, isShowModal: true })
          }}
        >
          Edit
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    })

    return dataTableColumns
  }

  updateList = (id, data, isDelete, isAdd) => {
    if (isAdd) {
      this.setState((state) => {
        const newCategoryItems = state["categoryItems"].map((item) => item)
        newCategoryItems.push(data)
        return {
          categoryItems: newCategoryItems,
          isShowAddModal: false,
        }
      })
    } else {
      this.setState((state) => {
        const newCategoryItems = !isDelete
          ? state["categoryItems"].map((item) =>
              item["id"] == id ? data : item
            )
          : state["categoryItems"].filter((item) => item["id"] != id)
        return {
          categoryItems: newCategoryItems,
          isShowModal: isDelete == true ? false : true,
        }
      })
    }
  }

  handleSearch = async ({ query, type }) => {
    // if (query.length <= 0) {

    //     this.setState({ isSearching: false }, () => {
    //         this.getCategoryItems()
    //     })

    // } else {

    //     const searchResult = await categoryService.search({ query, type })

    //     this.setState({ dataTableLoading: true })

    //     if (searchResult.success) {
    //         const { data } = searchResult
    //         this.setState({ categoryItems: data, dataTableLoading: false })
    //     } else {
    //         this.setState({ categoryItems: [], dataTableLoading: false })
    //     }
    // }
    if (query.length > 0) {
      console.log("searching")
      console.log(type)
      this.props.setSearchResultAction({ query, type })
    } else {
      this.props.restoreSearchAction()
    }
  }

  handlePageTitle = () => {
    return new Promise((resolve) => {
      const market = this.props.marketTitle
      const type = userService.getBookType()

      const title = `${
        market == "us"
          ? "United States"
          : market == "de"
          ? "Germany"
          : market == "uk"
          ? "United Kingdom"
          : ""
      } | `

      this.setState({ pageTitle: title }, () => resolve(null))
    })
  }

  handleEditModalClose =()=>{
    this.setState({isShowModal:false})
  }

  openBookSelector() {
    this.setState({ isOpenSelector: true })
  }

  render() {
    const {
      progressPending,
      pageTitle,
      isOpenSelector,
      categoryItems,
      dataTableColumns,
      dataTableLoading,
      isLoadedData,
      selectedCatId,
      selectedCat,
      isShowModal,
      isShowAddModal,
    } = this.state

    return (
      <Fragment>
        <BookTypeSelector
          isShow={isOpenSelector}
          handleClose={() => this.setState({ isOpenSelector: false })}
        />
    <EditCategoryModal handleEditModalClose={this.handleEditModalClose} viewModal={this.state.isShowModal} selectedCat ={this.state.selectedCat}/>
        {/* <ModalComponent
          show={isShowModal}
          size="lg"
          heading={"Category Item"}
          handleClose={{
            action: () => this.setState({ isShowModal: false }),
            name: "Close",
          }}
        >
          <ViewEditCategory
            catId={selectedCatId}
            updateList={this.updateList}
          ></ViewEditCategory>
        </ModalComponent> */}

        <ModalComponent
          show={isShowAddModal}
          size="lg"
          heading={"Add Category Item"}
          handleClose={{
            action: () => this.setState({ isShowAddModal: false }),
            name: "Close",
          }}
        >
          <AddCateforyRecord updateList={this.updateList}></AddCateforyRecord>
        </ModalComponent>

        <div className="row mb-12" style={{marginBottom:'1rem'}}>
          <div className="col-md-6">
            <div style={{ display: "flex" }}>
              <h1>{ this.props.marketTitle} |  </h1>
              <MediaSelector />
              <div className="d-flex justify-content-start col-md-6 text-right align-items-center">
                <button
                  className="btn btn-info mr-4"
                  disabled
                  onClick={() => this.setState({ isShowAddModal: true })}
                >
                  Add Record
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-6">
          <CategorySearch searchHandler={this.handleSearch} />
          </div>
        </div>

        {
          <Fragment>
            {/* <div className="row mb-2">
              <div className="col-md-7"></div>
              <div className="col-md-5">
                <CategorySearch searchHandler={this.handleSearch} />
              </div>
            </div> */}

            <DataTable
              title=""
              columns={dataTableColumns}
              data={this.props.selectedCategories}
              progressPending={this.props.dataLoading}
              pagination
              noHeader
              paginationRowsPerPageOptions={[25, 50, 75, 100]}
              paginationPerPage={25}
              //progressPending={progressPending}
              // onRowClicked={(row) => this.goToSingleListItem(row.id)}
            />
          </Fragment>
        }
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  console.log(state)

  return {
    selectedCategories: state.selectedCategories,
    marketTitle : state.marketTitle,
    dataLoading :state.isDataLoading
  }
}

export default connect(mapStateToProps, {
  restoreSearchAction,
  fetchAllCategoriesAction,
  setSearchResultAction,
})(CategoryListpage)
