import { userService } from "./user.service";
import HttpClient from "../helpers/http-client.service";

export class CategoryService extends HttpClient{

    constructor(){
        const resourceBase  =  `${userService.getMaket()}`
        const token = userService.getToken()
        super(resourceBase,token)
    }

    search = ({query,type}) =>{

        const url  =  this.resourceUrl + "/search"
        return this.postRequest(url, {query, type})
    }
    
    getCatNames = () => {

        const url  =  this.resourceUrl + "/category-names"
        return this.getRequest(url)
    }

    // getRetried = () => {

    //     const url  =  this.resourceUrl + "/retired"
    //     return this.getRequest(url)
    // }
    // getSkipped = () => {

    //     const url  =  this.resourceUrl + "/skipped"
    //     return this.getRequest(url)
    // }
}

export const categoryService = new CategoryService()
 