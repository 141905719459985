import { FETCH_ALL_CATEGORIES , CHANGE_FORMAT,SET_SEARCH_RESULTS,RESTORE_SEARCH,CHANGE_MARKET,DATA_LOADING_START,DATA_LOADING_END, UPDATE_CATEGORY_DATA} from "../actionTypes/actionTypes";


const fetchAllCategoriesAction = (data)=> (dispatch)=>{
  console.log("Calling dispatch")
  dispatch({type:FETCH_ALL_CATEGORIES,payload:data})
}
const changeMediaFormat = (data)=> (dispatch)=>{
  dispatch({type:CHANGE_FORMAT,payload:data})
}

const setSearchResultAction = (data)=> (dispatch)=>{
  dispatch({type:SET_SEARCH_RESULTS,payload:data})
}

const restoreSearchAction = (data)=> (dispatch)=>{
  dispatch({type:RESTORE_SEARCH})
}

const startDataLoadingAction =()=>(dispatch)=>{
  dispatch({type:DATA_LOADING_START})
}

const endDataLoadingAction =()=>(dispatch)=>{
  dispatch({type:DATA_LOADING_END})
}

const changeMarketAction = (data)=>(dispatch)=>{
  dispatch({type:CHANGE_MARKET,payload:data})
}

const updateCategoryAction = (data)=>(dispatch)=>{
  dispatch({type:UPDATE_CATEGORY_DATA,payload:data})
}

export {fetchAllCategoriesAction,changeMediaFormat,setSearchResultAction,restoreSearchAction,startDataLoadingAction,endDataLoadingAction,changeMarketAction,updateCategoryAction}