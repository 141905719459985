
import React, { Fragment, useState } from "react"

import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

export default function ModalComponent({ size, heading, show, children, backdrop,keyboard,handleClose }) {
 //   console.log(`{ size, heading, show, children, backdrop,keyboard,handleClose } -->`,{ size, heading, show, children, backdrop,keyboard,handleClose });
    return (<Modal size={size} show={show} onHide={handleClose.action} backdrop={ backdrop != null ? backdrop : true } keyboard={keyboard != null  ? keyboard : true} animation={true}>
        <Modal.Header closeButton>
            <Modal.Title>{heading}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {children}
        </Modal.Body>
    </Modal>)


}
