import React, { Fragment, useState } from 'react'
import { changeMediaFormat } from '../../../store/actions/category.actions';
import { useDispatch } from 'react-redux';
import { userService } from '../services/entity-services/user.service';
import Dropdown from 'react-bootstrap/Dropdown';


function MediaSelector(){
  const dispatch = useDispatch()

  const [wording,setWording] = useState("All")
  const handleTypeSwitch  = type => {
    console.log(type);
    userService.setBookType(type)
    dispatch(changeMediaFormat(type))
    setWording(type)

    //window.location.reload()
}

return(
  <Fragment>


<Dropdown align={"start"|"end"}>
      <Dropdown.Toggle variant="success" id="dropdown-basic">
     {wording}
      </Dropdown.Toggle>

      <Dropdown.Menu>
      <Dropdown.Item href="#" onClick={()=> handleTypeSwitch("All")}>All</Dropdown.Item>
        <Dropdown.Item href="#" onClick={()=> handleTypeSwitch("Audible")}>Audible</Dropdown.Item>
        <Dropdown.Item href="#" onClick={()=> handleTypeSwitch("Book")}>Book</Dropdown.Item>
        <Dropdown.Item href="#" onClick={()=> handleTypeSwitch("Kindle")}>Kindle</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  </Fragment>
)

}


export default MediaSelector