import React, { Fragment } from 'react'
import Sidebar from './components/sidebar/Sidebar'
import Header from './components/header/Header'
import PageContainer from './components/page-container/PageContainer'
import layoutService from '../commons/services/helpers/layout.service'
import { ToastContainer } from 'react-toastify'

export default function Layout({ routes , noSidebar }) {

    if(!noSidebar){
        layoutService.handleNoSidebar()
    }

    return (
        <Fragment>
            <Header></Header>
            <main className="u-main" role="main">
                {(noSidebar) ? <Sidebar /> : ""}
                <PageContainer routes={routes} />
                <ToastContainer/>
            </main>
        </Fragment>
    )
}
