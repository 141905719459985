import { FETCH_ALL_CATEGORIES,CHANGE_FORMAT ,SET_SEARCH_RESULTS, RESTORE_SEARCH,CHANGE_MARKET,DATA_LOADING_END,DATA_LOADING_START, UPDATE_CATEGORY_DATA} from "../actionTypes/actionTypes";

const initialState = {
  allCategories: [],
  selectedCategories : [],
  marketTitle : 'United States',
  isDataLoading: false,
};


const categoryReducer = (state=initialState,action)=>{
  switch(action.type){
    case FETCH_ALL_CATEGORIES:
      console.log(action)
      let allCategories = [...action.payload]
      let selectedCategories = [...action.payload]
      return{
        ...state,
        allCategories,selectedCategories
      }
    case CHANGE_FORMAT:
      console.log(action)
      let selectedCats = []
      if(action.payload === "All"){
        selectedCats =[...state.allCategories]
      }
      else{
        selectedCats = [...state.allCategories].filter((e)=>(e.MediaType===action.payload.toUpperCase()))
      }
      console.log(selectedCats)
      return{
        ...state,
        selectedCategories:selectedCats
      }
    case SET_SEARCH_RESULTS:
      const {type,query} = action.payload
      console.log(type,query)
      let searchedCats = [...state.allCategories].filter((e)=>e[type].toLowerCase().includes(query.toLowerCase()))
      return {...state,
        selectedCategories:searchedCats}
    case RESTORE_SEARCH:
      let restoreCats = [...state.allCategories]
      return {
        ...state,selectedCategories:restoreCats
      }
    case CHANGE_MARKET:
      let market = action.payload
      return{
        ...state,marketTitle : market
      }
    case DATA_LOADING_START:
      return{
        ...state,isDataLoading:true
      }
    case DATA_LOADING_END:
      return {
        ...state,isDataLoading:false
      }
    case UPDATE_CATEGORY_DATA:
      const {data,id} = action.payload
      delete data.isActive
      let oldCategoryData = [...state.allCategories]
      let updatedKeys = Object.keys(data)
      oldCategoryData.map((e)=>{
        if(e.CategoryID === id){
          updatedKeys.map((j)=>{
          
            e[j] = data[j]
            console.log(e)
          })
      }})

      return {
        ...state,
        selectedCategories:oldCategoryData,
        allCategories:oldCategoryData
      }

    default:
      return state
  }
}

export default categoryReducer