import React, { Component, Fragment } from "react";
import DataTable from "react-data-table-component";
import { categoryService } from "../../../core/commons/services/entity-services/category.service";
import ViewEditCategory from "../components/view-edit-category";

import ModalComponent from "../../../core/commons/components/modal.component"
import CategorySearch from "../components/category-search";
import { userService } from "../../../core/commons/services/entity-services/user.service";
import BookTypeSelector from "../../../core/commons/components/book-type-selector";
import moment from "moment";
import AddCateforyRecord from "../components/add-category-record";

export default class RetiredListpage extends Component {

    constructor() {
        super()
        const dataTableColumns = this.setupDataColumns()
        this.state = {
            dataTableColumns: dataTableColumns,
            categoryItems: [],
            dataTableLoading: false,
            isLoadedData: false,
            selectedCatId: null,
            isShowModal: false,
            pageTitle: "",
            progressPending:true,
            isShowAddModal:false
        }
    }


    async getCategoryItems() {

        const items = await categoryService.getAllRetired()
        if (!items.success) {
            alert("Failed to get category data")
            this.setState({progressPending:false })
        } else {
            this.setState({ categoryItems: items.data ,progressPending:false })
        }

    }

    async componentWillMount() {

        try {
            await this.handlePageTitle()
            await this.getCategoryItems()
        } catch (error) {
            console.log(error)
        }

    }


    setupDataColumns = () => {

        const dataTableColumns = [
            {
                name: "Main Category",
                selector: "catName",
                grow: 2,
                wrap: true
            },
            {
                name: "Category String",
                selector: "catString",
                grow: 4,
                wrap: true
            },
            {
                name: "Amazon Url",
                selector: "amazonUrl",
                cell: (row) => {
                    let url  =  row.amazonUrl
                    if(!url.includes("https://")){
                        url  = `https://${url}`
                    }
                    return <a href={url} target="_blank">Open</a>
                }
            },
            {
                name: "#1 ABSR",
                selector: "one",
                grow: 1,
                wrap: true,
                sortable: true,

            },
            {
                name: "#10 ABSR",
                selector: "ten",
                grow: 1,
                wrap: true,
                sortable: true,
            },
            // {
            //     name: "One Reviews",
            //     selector: "one_num_reviews",
            //     grow: 1,
            //     wrap: true
            // },
            {
                name: "#1 Avg Rating",
                selector: "one_avg_rating",
                grow: 1,
                // wrap: true,
                sortable: true,

            },
            {
                name: "Status",
                selector: "isDeleted",
                grow: 1,
                wrap: true,
                cell : ({isDeleted}) => <span class={`badge badge-${isDeleted ? "danger" : "success"}`}>{isDeleted ? "Deleted" : "Live"}</span>
            },
            {
                name: "#10 Avg Rating",
                selector: "ten_avg_rating",
                grow: 1,
                // wrap: true,    
                sortable: true,

            },
            {
                name: "isRetired",
                selector: "isRetired",
                grow: 1,
                wrap: true
            },
            {
                name: "Last Update",
                selector: "updated_on",
                grow: 1,
                format : (row) =>  moment(row['updated_on']).format("MM/DD/YYYY"),
                wrap: true,
                sortable: true,

            },
            {
                name: "Created At",
                selector: "created_on",
                format : (row) =>  moment(row['created_on']).format("MM/DD/YYYY"),
                grow: 1,
                wrap: true,
                sortable: true,

            },

        ]

        dataTableColumns.push({
            cell: (row) => <button className='btn btn-outline-primary p-1' style={{ "width": "80px" }} onClick={() => { this.setState({ selectedCatId: row.id, isShowModal: true }) }} >Edit</button>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        })

        return dataTableColumns

    }


    updateList = (id, data, isDelete,isAdd) => {


        if(isAdd){
            this.setState(state => {
                const newCategoryItems = state['categoryItems'].map(item => item)
                newCategoryItems.push(data)
                return {
                    categoryItems: newCategoryItems,
                    isShowAddModal: false 
                }
            })
        }else{
            this.setState(state => {
                const newCategoryItems = !isDelete ? state['categoryItems'].map(item => item['id'] == id ? data : item) : state['categoryItems'].filter(item => item['id'] != id)
                return {
                    categoryItems: newCategoryItems,
                    isShowModal: isDelete == true ? false : true
                }
            })
        }


    }

    handleSearch = async ({ query, type }) => {

        if (query.length <= 0) {

            this.setState({ isSearching: false }, () => {
                this.getCategoryItems()
            })

        } else {

            const searchResult = await categoryService.search({ query, type })

            this.setState({ dataTableLoading: true })

            if (searchResult.success) {
                const { data } = searchResult
                this.setState({ categoryItems: data, dataTableLoading: false })
            } else {
                this.setState({ categoryItems: [], dataTableLoading: false })
            }
        }



    }


    handlePageTitle = () => {


        return new Promise((resolve)=>{

            const market = userService.getMaket()
            const type = userService.getBookType()
    
            const title = `${market == 'us' ? 'United States' : market == "de" ? "Germany" : market == "uk" ? "United Kingdom" : ""} |  ${type == "book" ? "Book" : type == "ebook" ?  "eBook": "Audible"}`
    
            this.setState({ pageTitle: title },() => resolve(null))

        })

    }

    openBookSelector() {
        this.setState({ isOpenSelector: true })
    }

    render() {


        const {progressPending, pageTitle, isOpenSelector, categoryItems, dataTableColumns, dataTableLoading, isLoadedData, selectedCatId, isShowModal,isShowAddModal } = this.state

        return (

            <Fragment>



                <BookTypeSelector isShow={isOpenSelector} handleClose={() => this.setState({ isOpenSelector: false })} />

                <ModalComponent show={isShowModal}
                    size="lg"
                    heading={"Category Item"}
                    handleClose={{ action: () => this.setState({ isShowModal: false }), name: "Close" }}>

                    <ViewEditCategory catId={selectedCatId} updateList={this.updateList}></ViewEditCategory>

                </ModalComponent>

                <ModalComponent show={isShowAddModal}
                    size="lg"
                    heading={"Add Category Item"}
                    handleClose={{ action: () => this.setState({ isShowAddModal: false }), name: "Close" }}>
                    
                    <AddCateforyRecord updateList={this.updateList}></AddCateforyRecord>

                </ModalComponent>

                <div className="row mb-4">
                    <div className="col-md-6">

                        <h1>{pageTitle}</h1>
                    </div>

                    <div className="d-flex justify-content-end col-md-6 text-right align-items-center">
                        <button className="btn btn-primary mr-4" onClick={() => this.setState({isShowAddModal:true})}>
                        Add Record
                        </button>
                        <button className="btn btn-primary" style={{ "cursor": "pointer" }} onClick={() => this.setState({ isOpenSelector: true })}>Switch Format</button>
                    </div>
                </div>

                {

                        <Fragment>

                            <div className="row mb-2">
                                <div className="col-md-7"></div>
                                <div className="col-md-5">
                                    <CategorySearch searchHandler={this.handleSearch} />
                                </div>
                            </div>

                            <DataTable
                                title=""
                                columns={dataTableColumns}
                                data={categoryItems}
                                progressPending={dataTableLoading}
                                pagination
                                noHeader
                                paginationRowsPerPageOptions={[25,50,75,100]}
                                paginationPerPage={25}
                                progressPending={progressPending}
                            // onRowClicked={(row) => this.goToSingleListItem(row.id)}
                            />
                        </Fragment>
                    
                }

            </Fragment >


        )

    }

}