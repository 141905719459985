import React, { Fragment } from 'react'
import ModalComponent from './modal.component'
import { userService } from '../services/entity-services/user.service';
import { useDispatch } from 'react-redux';
import { changeMediaFormat } from '../../../store/actions/category.actions';
export default function BookTypeSelector({isShow,handleClose , keyboard, backdrop}) {
    const dispatch = useDispatch()
    const handleTypeSwitch  = type => {
        console.log(type);
        userService.setBookType(type)
        dispatch(changeMediaFormat(type))
        handleClose()
        //window.location.reload()
    }
 

    return (
        <Fragment>
           <ModalComponent show={isShow}
                    size="sm"
                    keyboard={keyboard}
                    backdrop={backdrop}
                    heading={"Change Format"}
                    handleClose={{ action: handleClose, name: "Close" }}>

                    <div className="form-row book-btn-container text-center">
                        {/* <select className="form-control" onChange={(e) => e.target.value ? handleTypeSwitch(e.target.value) : null } name="" id="">
                            <option>Select Book Type</option>
                            <option value="book">Book</option>
                            <option value="ebook">EBook</option>
                        </select> */}
                        <div className="col-md-12" style={{paddingBottom:'0.5rem'}}>
                            <button className="w-100 btn btn-primary" onClick={()=> handleTypeSwitch("all")}>All</button>
                        </div>
                        <div className="col-md-12" style={{paddingBottom:'0.5rem'}}>
                            <button className="w-100 btn btn-primary" onClick={()=> handleTypeSwitch("audible")}>Audible</button>
                        </div>
                        <div className="col-md-12" style={{paddingBottom:'0.5rem'}}>
                            <button className="w-100 btn btn-primary" onClick={()=> handleTypeSwitch("book")}>Book</button>
                        </div>
                        <div className="col-md-12" style={{paddingBottom:'0.5rem'}}>
                            <button className="w-100 btn btn-primary" onClick={()=> handleTypeSwitch("kindle")}>eBook</button>
                        </div>
                    </div>

                </ModalComponent>
        </Fragment>
    )
}
