import { toast } from "react-toastify";

export const showToast = (message, success) => {

    const type = success ? "success" : "error"
    toast[type](message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
    });
}

export const somethingWentWrong = () => showToast("Oops something went wrong")

export const failedToLoad = (entity) => showToast(`Failed to load ${entity || ""}`)

const toastService = {
    showToast, 
    somethingWentWrong,
    failedToLoad
}

export default toastService