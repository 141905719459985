import React, { Fragment } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { withResizeDetector } from 'react-resize-detector'
import layoutService from '../../../commons/services/helpers/layout.service'


function SidebarComponent({width,height}) {

    const history =  useHistory()
    
    const logoutUser = () => {
        localStorage.clear()
        setTimeout(() => {
            window.location.href = "/"
        }, 500)
    }

    const handleNavigation = (route) => {

        if(width <=557){
            layoutService.handleMobileSidebarToggle(false)
        }
       
        history.push({pathname:route})

    }

    return (
        <Fragment>
            <aside id="sidebar" className="u-sidebar">
                <div className="u-sidebar-inner">
                    <nav className="u-sidebar-nav">
                        <ul className="u-sidebar-nav-menu u-sidebar-nav-menu--top-level">

                            <li className="u-sidebar-nav-menu__item">
                                <Link className="u-sidebar-nav-menu__link" onClick={() => handleNavigation("/")}>
                                    <i className="fa fa-cubes u-sidebar-nav-menu__item-icon"></i>
                                    <span className="u-sidebar-nav-menu__item-title">Base</span>
                                </Link>

                            </li>



                            <li className="u-sidebar-nav-menu__item">
                                <span style={{ "cursor": "pointer" }} className="u-sidebar-nav-menu__link" onClick={logoutUser} >
                                    <i className="fa fa-sign-out u-sidebar-nav-menu__item-icon"></i>
                                    <span className="u-sidebar-nav-menu__item-title">Logout</span>
                                </span>

                            </li>
                        </ul>
                    </nav>
                </div>
            </aside>
        </Fragment>
    )
}

const Sidebar =  withResizeDetector(SidebarComponent)

export default Sidebar
