import HttpClient from "../helpers/http-client.service"

export const USER_LOCALSTORAGE_KEYS = {
    "token": "user_token",
    "userInfo": "user_info",
    "market":"market_info",
    "bookType":"book_type"
}


export class UserService extends HttpClient {

    constructor() {
        super("users")
    }

    isLoggedIn() {
        console.log(localStorage.getItem(USER_LOCALSTORAGE_KEYS.token))
        return (localStorage.getItem(USER_LOCALSTORAGE_KEYS.token)) ? true : false
    }

    getToken() {
        return localStorage.getItem(USER_LOCALSTORAGE_KEYS.token)
    }

    addToken(token) {
        localStorage.setItem(USER_LOCALSTORAGE_KEYS.token, token)
    }


    setMarket(market){
        localStorage.setItem(USER_LOCALSTORAGE_KEYS.market, market)
    }
    
    setBookType(bookType){
        localStorage.setItem(USER_LOCALSTORAGE_KEYS.bookType, bookType)
    }

    getMaket(){
        const market  =  localStorage.getItem(USER_LOCALSTORAGE_KEYS.market)
        return market 
    }
    getBearerToken(){
        const token = localStorage.getItem(USER_LOCALSTORAGE_KEYS.user_token)
        return token
    }
    getBookType(){
        const bookType  =  localStorage.getItem(USER_LOCALSTORAGE_KEYS.bookType)
        return bookType 
    }

    adddUserInfo(userInfo) {

        const { token, ...user } = userInfo

        this.addToken(token)
        localStorage.setItem(USER_LOCALSTORAGE_KEYS.userInfo, JSON.stringify(user))
    }

    logout() {
        localStorage.removeItem(USER_LOCALSTORAGE_KEYS.token)
    }


    async login(credentials) {
        console.log(this.resourceUrl)
        console.log(credentials)
        return await this.postRequest("/login", credentials)
    }

    async register(formData) {
        const urlPath = `${this.resourceUrl}/register`
        return await this.postRequest(urlPath, formData)
    }

    async getLocationList(){
        const urlPath =  `${this.resourceUrl}/locations`
        return await this.getRequest(urlPath)
    }
}

export const userService = new UserService()
