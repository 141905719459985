import React, { useState, useEffect, useCallback } from "react"

import { Modal, Button, Form, Container, Col, Row } from "react-bootstrap"
import { useForm, Controller } from "react-hook-form"

import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import { updateCategoryAction } from "../../../store/actions/category.actions"
import { categoryService } from "../../../core/commons/services/entity-services/category.service"
import toastService from "../../../core/commons/services/helpers/toast.service"

function EditCategoryModal({ handleEditModalClose, viewModal, selectedCat }) {

  const dispatch = useDispatch()
  let patternTwoDigisAfterComma = /^\d+(\.\d{0,1})?$/
  const validationSchema = Yup.object().shape({
    Percent_KU: Yup.number()
      .min(5, "value must be greater than or equal to 5")
      .max(95, "value must be less than or equal to 95").nullable(true),
    Percent_Large_Publisher: Yup.number()
      .min(5, "value must be greater than or equal to 5")
      .max(95, "value must be less than or equal to 95").nullable(true),
    AverageReviewScore: Yup.number()
      .positive()
      .test(
        "is-decimal",
        "The amount should be a decimal with maximum one digit after comma",
        (val) => {
          if (val !== undefined) {
            return patternTwoDigisAfterComma.test(val)
          }
          return true
        }
      )
      .min(0, "Value must be greater than 0")
      .max(5, "value must be less than 5.0").nullable(true),
    ABSR1: Yup.number("ABSR must be an integer").nullable(true),
    ABSR10: Yup.number("ABSR must be an integer").nullable(true),
    AverageAge: Yup.number("Age must be an integer").nullable(true),
    AverageDuration: Yup.number("Duration must be an integer").nullable(true),
    AverageRating: Yup.number("Rating must be an integer").nullable(true),
    AveragePageCount: Yup.number("Page Count must be an integer").nullable(true),
    AveragePrice: Yup.number("Price must be an integer").nullable(true),
    AveragePubPrice: Yup.number("Large Publisher Price must be an Integer").nullable(true),
    AverageIndiePrice: Yup.number("Indie Publisher Price must be an integer").nullable(true),
    AverageSeriesLength: Yup.number("Series Length must be an integer").nullable(true),
    AverageMultiChannel: Yup.number("Muti Channel must be an integer").nullable(true),
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(validationSchema) })

  const [show, setShow] = useState(false)
  const [formData, setFormData] = useState(null)
  const [disableField, setDisableField] = useState(true)

  const handleClose = () => {
    reset(selectedCat)
    setShow(false)
    setDisableField(true)
    handleEditModalClose()
    
  }

  useEffect(() => {
   
    setShow(viewModal)
  }, [viewModal])

  useEffect(() => {
    reset(selectedCat)
    setFormData(selectedCat)

  }, [reset, selectedCat])

  const handleFormSubmit = async(e) => {
    const fieldKeys = Object.keys(e)
    const updatedData = {"isActive":true}  
    fieldKeys.map((j)=>{
      if(selectedCat[j] !== e[j])
      {
        updatedData[j] = e[j]
      }
    })
   const response = await categoryService.updateCategory(e.CategoryID,updatedData)
    console.log(response)
    if(response.status===200){
      toastService.showToast(`Cateogory ${e.CategoryID} updated sucessfully`,true)
    }else{
      toastService.showToast("something went Wrong")
    }
   dispatch(updateCategoryAction({data:updatedData,id:e.CategoryID}))
  
    handleClose()
  }

  const handleEditMode = () => {
    setDisableField(!disableField)
  }
  return (
    <>
      <Modal show={show} onHide={handleClose} centered size="lg">
        <Modal.Header closeButton>
          <Container>
            <Form.Check
              type="switch"
              id="custom-switch"
              onChange={handleEditMode}
              label="Change to Edit Mode"
            />
          </Container>
        </Modal.Header>
        <Modal.Body>
          {formData !== null ? (
            <Container>
              <Form onSubmit={handleSubmit(handleFormSubmit)}>
                <h1>Category Data</h1>
                <Form.Group className="mb-3" controlId="CategoryID">
                  <Form.Label>Category ID</Form.Label>
                  <Form.Control
                    disabled={true}
                    type="text"
                    defaultValue={formData.CategoryID}
                    {...register("CategoryID")}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="categoryString">
                  <Form.Label>Category String</Form.Label>
                  <Form.Control
                    disabled={disableField}
                    type="text"
                    defaultValue={formData.CategoryString}
                    {...register("CategoryString")}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="CategoryURL">
                  <Form.Label>Category URL</Form.Label>
                  <Form.Control
                    disabled={true}
                    type="text"
                    defaultValue={formData.CategoryURL}
                    {...register("CategoryURL")}
                  />
                </Form.Group>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="MediaType">
                      <Form.Label>Media Type</Form.Label>
                      <Form.Control
                        disabled={disableField}
                        as="select"
                        defaultValue={formData.MediaType}
                        {...register("MediaType")}
                      >
                        <option value="AUDIBLE">Audible</option>
                        <option value="BOOK">Book</option>
                        <option value="KINDLE">Kindle</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="MarketType">
                      <Form.Label>Market Type</Form.Label>
                      <Form.Control
                        disabled={disableField}
                        as="select"
                        defaultValue={formData.MarketType}
                        {...register("MarketType")}
                      >
                        <option value="US">US</option>
                        <option value="UK">UK</option>
                        <option value="DE">DE</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="Percent_KU">
                      <Form.Label>Percent KU</Form.Label>
                      <Form.Control
                        disabled={disableField}
                        type="text"
                        defaultValue={formData.Percent_KU}
                        isInvalid={!!errors.Percent_KU}
                        {...register("Percent_KU")}
                      />
                      
                      <Form.Control.Feedback type="invalid">
                        {errors.Percent_KU?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      controlId="Percent_Large_Publisher"
                    >
                      <Form.Label>Percent Large Publisher</Form.Label>
                      <Form.Control
                        disabled={disableField}
                        type="text"
                        defaultValue={formData.Percent_Large_Publisher}
                        isInvalid={!!errors.Percent_Large_Publisher}
                        {...register("Percent_Large_Publisher")}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.Percent_Large_Publisher?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="RetiredFlag">
                      <Form.Label>Retired Flag</Form.Label>
                      <Form.Control
                        disabled={disableField}
                        as="select"
                        defaultValue={formData.RetiredFlag}
                        {...register("RetiredFlag")}
                      >
                        <option value="0">False</option>
                        <option value="1">True</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <hr />
                <h1>ABSR Data</h1>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="ABSR1">
                      <Form.Label>ABSR #1</Form.Label>
                      <Form.Control
                        disabled={disableField}
                        type="text"
                        defaultValue={formData.ABSR1}
                        isInvalid={!!errors.ABSR1}
                        {...register("ABSR1")}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.ABSR1?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="ABSR10">
                      <Form.Label>ABSR #10</Form.Label>
                      <Form.Control
                        disabled={disableField}
                        type="text"
                        isInvalid={!!errors.ABSR10}
                        defaultValue={formData.ABSR10}
                        {...register("ABSR10")}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.ABSR10?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <hr />
                <h1>Insights Data</h1>
                <Row>
                  {" "}
                  <Col>
                    <Form.Group className="mb-3" controlId="AverageAge">
                      <Form.Label>Average Age</Form.Label>
                      <Form.Control
                        disabled={disableField}
                        type="text"
                        isInvalid={!!errors.AverageAge}
                        defaultValue={formData.AverageAge}
                        {...register("AverageAge")}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.AverageAge?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="AverageDuration">
                      <Form.Label>Average Duration</Form.Label>
                      <Form.Control
                        disabled={disableField}
                        type="text"
                        isInvalid={!!errors.AverageDuration}
                        defaultValue={formData.AverageDuration}
                        {...register("AverageDuration")}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.AverageDuration?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="AverageRating">
                      <Form.Label>Average Rating</Form.Label>
                      <Form.Control
                        disabled={disableField}
                        type="text"
                        defaultValue={formData.AverageRating}
                        isInvalid={!!errors.AverageRating}
                        {...register("AverageRating")}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.AverageRating?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="AverageReviewScore">
                      <Form.Label>Average Review Score</Form.Label>
                      <Form.Control
                        disabled={disableField}
                        type="text"
                        defaultValue={formData.AverageReviewScore}
                        isInvalid={!!errors.AverageReviewScore}
                        {...register("AverageReviewScore")}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.AverageReviewScore?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="AveragePageCount">
                      <Form.Label>Average Page Count</Form.Label>
                      <Form.Control
                        disabled={disableField}
                        type="text"
                        isInvalid={!!errors.AveragePageCount}
                        defaultValue={formData.AveragePageCount}
                        {...register("AveragePageCount")}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.AveragePageCount?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="AveragePrice">
                      <Form.Label>Average Price</Form.Label>
                      <Form.Control
                        disabled={disableField}
                        type="text"
                        isInvalid={!!errors.AveragePageCount}
                        {...register("AveragePrice")}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.AveragePageCount?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="AveragePubPrice">
                      <Form.Label>Average Large Publisher Price</Form.Label>
                      <Form.Control
                        disabled={disableField}
                        type="text"
                        defaultValue={formData.AveragePubPrice}
                        {...register("AveragePubPrice")}
                        isInvalid={!!errors.AveragePubPrice}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.AveragePubPrice?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="AverageIndiePrice">
                      <Form.Label>Average Indie Publisher Price</Form.Label>
                      <Form.Control
                        disabled={disableField}
                        type="text"
                        defaultValue={formData.AverageIndiePrice}
                        {...register("AverageIndiePrice")}
                        isInvalid={!!errors.AverageIndiePrice}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.AverageIndiePrice?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      controlId="AverageSeriesLength"
                    >
                      <Form.Label>Average Series Length</Form.Label>
                      <Form.Control
                        disabled={disableField}
                        type="text"
                        defaultValue={formData.AverageSeriesLength}
                        {...register("AverageSeriesLength")}
                        isInvalid={!!errors.AverageSeriesLength}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.AverageSeriesLength?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      controlId="AverageMultiChannel"
                    >
                      <Form.Label>Average Multi Channel</Form.Label>
                      <Form.Control
                        disabled={disableField}
                        type="text"
                        isInvalid={!!errors.AverageMultiChannel}
                        defaultValue={formData.AverageMultiChannel}
                        {...register("AverageMultiChannel")}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.AverageMultiChannel?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group className="mb-3" controlId="LargePublisherList">
                  <Form.Label>Large Publisher List</Form.Label>
                  <Form.Control
                    disabled={disableField}
                    as="textarea"
                    rows={4}
                    defaultValue={formData.LargePublisherList}
                    {...register("LargePublisherList")}
                  />
                </Form.Group>
                <hr />
                <Row>
                  <Col>
                 
                  </Col>
                  <Col style={{ display: "flex", placeContent: "end" }}>
                    <Button
                      style={{ marginRight: "1rem" }}
                      variant="secondary"
                      onClick={handleClose}
                    >
                      Close
                    </Button>
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={disableField}
                    >
                      Submit Changes
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Container>
          ) : (
            ""
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default EditCategoryModal
