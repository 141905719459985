import React, { Fragment, useState } from "react";

export default function CategorySearch({ searchHandler }) {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchType, setSearchType] = useState("CategoryString");

  const escapeQuotes = (txt) => txt.replace(/'/g, "\\'");

  const handleQueryChange = (event) => setSearchQuery(event.target.value);
  const handleTypeChange = (event) => setSearchType(event.target.value);

  const handleSearch = () => {
    searchHandler({ query: escapeQuotes(searchQuery), type: searchType });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      //   console.log('do validate');
      handleSearch();
    }
  };

  const handleKeyUp = () => {
    if (searchQuery.length <= 0) {
      handleSearch();
    }
  };

  return (
    <Fragment>
      <div className="input-group search-field">
        {/*<input value={searchQuery} className="form-control" type="search" placeholder="Type to search…" onChange={handleQueryChange} />
                <button className="btn-link input-group-prepend u-header-search__btn" type="submit" onClick={handleSearch} >
                    <i className="fa fa-search"></i>
                </button> */}

        <span className=" w-100 form-icon-wrapper">
          <span className="form-icon form-icon--left">
            <select
              className="custom-select"
              value={searchType}
              onChange={handleTypeChange}
            >
              {/* <option value={"parent"}>Parent Category</option> */}
              <option value={"CategoryString"}>Category String</option>
              {/* <option value={"url"}>Amazon Url</option> */}
            </select>
          </span>
          <span className="form-icon  form-icon--right" onClick={handleSearch}>
            <i className="fa fa-search form-icon__item"></i>
          </span>
          <input
            type="text"
            onen
            value={searchQuery}
            onKeyUp={handleKeyUp}
            onKeyDown={handleKeyDown}
            className="form-control form-icon-input-left"
            type="search"
            placeholder="Type to search…"
            onChange={handleQueryChange}
          />
        </span>
      </div>
    </Fragment>
  );
}
