import React, { Component } from 'react'
import './App.css';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import LoginPage from './modules/authentication/pages/login/LoginPage';
import RegisterPage from './modules/authentication/pages/register/RegisterPage';
import Layout from './core/Layouts/Layout';
import { userService } from './core/commons/services/entity-services/user.service';
import CATEGORY_MANAGEMENT_ROUTES from './modules/dashboard/dashboard.routes';

const APP_ROUTES = {
  dashboardRoute: CATEGORY_MANAGEMENT_ROUTES,
  fallbackRoutes: [
    {
      path: "/",
      exact: true,
      component: () => <Redirect to="/dashboard" />,
      isSideBar: true,
      label: "List"
    },
    {
      path: "/retiredlist",
      exact: true,
      component: () => <Redirect to="/retiredlist" />,
      isSideBar: true,
      label: "List"
    },
    {
      path: "/skipedlist",
      exact: true,
      component: () => <Redirect to="/skipedlist" />,
      isSideBar: true,
      label: "List"
    },
    {
      path: "/**",
      exact: true,
      component: () => <Redirect to="/" />,
      isSideBar: false,
      label: "List"
    },
  ]

}

export default class App extends Component {

  constructor() {
    super()
    const isLoggedIn = userService.isLoggedIn()
    // const isLoggedIn = true
    this.state = {
      isLoggedIn: isLoggedIn
    }
  }


  async componentDidMount() {

    const isLoggedIn = userService.isLoggedIn()

    // await this.setLocations()

    document.querySelector("body").style.zoom = isLoggedIn ? "90%" : "100%"

    const currentMarket = userService.getMaket()
    const currentType = userService.getBookType()

    if (currentMarket == null) {
      userService.setMarket("us")
    }
    if (currentType == null) {
      userService.setBookType("all")
    }

    console.log(`isLoggedIn ->`, isLoggedIn);
    this.setState({ isLoggedIn })

  }

  authenticationRoute() {
    return (
      <Switch>
        <Route path="/" exact component={() => <Redirect to="/login" />} />
        <Route path="/login" component={LoginPage} />
        <Route path="/register-backup" component={RegisterPage} />
        <Route path="/**" component={() => <Redirect to="/" />} />
      </Switch>
    )
  }

  dashboardRoute() {
    return <Layout routes={APP_ROUTES} />
  }

  render() {
    const { isLoggedIn } = this.state
    return (
      <Router>
        {
          (isLoggedIn) ? (this.dashboardRoute()) : (this.authenticationRoute())
        }
      </Router>)
  }
}

// export default App;
